import { HeaderText } from '@netspresso/components';
import React from 'react';
import { ModelSelect } from '../../../../../../../../components';
import { BASE_MODEL, MODEL_ID, MODEL_NAME } from '../../../../../../../../constants';
import { useCompressFormContext } from '../../../../../../../../context';
import { CompressModel } from '../../../../../../../../lib';

type SelectBaseModelProps = {
  models: CompressModel[];
};

export const SelectBaseModel: React.FC<SelectBaseModelProps> = ({ models }) => {
  const {
    compressForm: { setValue, watch },
  } = useCompressFormContext();
  const watched = watch();

  const handleChangeModel: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;
    const baseModel = models.find((model) => model.model_id === value);

    if (baseModel) {
      setValue(BASE_MODEL, baseModel, { shouldDirty: true, shouldValidate: true });
    }
  };

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Select a base model
      </HeaderText>
      <section className="mb-8">
        <label className="font-semibold mb-2 block text-sm text-darkGray" htmlFor="model-select">
          Base model *
        </label>
        <div className="flex flex-row items-center">
          <ModelSelect
            id="model-select"
            className="font-semibold mb-2"
            width="w-1/3"
            label="Base model *"
            options={models}
            labelProp={MODEL_NAME}
            value={watched[BASE_MODEL]?.model_id}
            valueProp={MODEL_ID}
            onChange={handleChangeModel}
          />
        </div>
      </section>
    </>
  );
};
