import { OptionType, OutlineButton, Select, SubmitButton } from '@netspresso/components';
import { NOT_NUMBER, NUMBER_WITH_DIGIT } from '@netspresso/shared';
import React, { useEffect, useState } from 'react';
import { METHOD_INFOS, RECOMMENDATION_METHOD, RECOMMENDATION_RATIO } from '../../../../constants';
import { useCompressFormContext } from '../../../../context';
import { isMethodInfoKey, MethodInfo } from '../../../../lib';
import { ConfigToolTips, MethodTooltips } from './components';

type RecommendationConfigsProps = {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  submitCallback: VoidFunction;
};

export const RecommendationConfigs: React.FC<RecommendationConfigsProps> = ({ onClose, onSubmit, submitCallback }) => {
  const {
    compressForm: { setValue, watch },
  } = useCompressFormContext();
  const recommendationMethod = watch(RECOMMENDATION_METHOD);
  const recommendationRatio = watch(RECOMMENDATION_RATIO);
  const [methodInfo, setMethodInfo] = useState<MethodInfo>();
  const [currentValue, setCurrentValue] = useState('');
  const [recommendMethods, setRecommendMethods] = useState<OptionType[]>([]);

  const onSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    // 현재는 1가지이지만 method 두개가 되면 ratio까지 핸들링 해야함 현재는 사실상 onChange가 일어나지 않음
    const { value } = event.target;

    if (isMethodInfoKey(value)) {
      setValue(RECOMMENDATION_METHOD, value);
    }
  };

  const handleChangeRatio: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(RECOMMENDATION_RATIO, parseFloat(value));
  };

  const onChangeRatio: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setCurrentValue(value);
  };

  const onSubmitHandler: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    onBlurRatio();
    submitCallback();
  };

  const onBlurRatio = () => {
    let maybeNumber = currentValue.trim();

    maybeNumber = maybeNumber.replace(NOT_NUMBER, '').replace(',', '.').replace('--', '-');

    if (maybeNumber.length > 2) {
      maybeNumber = maybeNumber.charAt(0) + maybeNumber.substring(1).replace('-', '');
    }

    if (NUMBER_WITH_DIGIT.test(maybeNumber)) {
      maybeNumber = `${Math.max(methodInfo?.ratio.min || 1, parseFloat(maybeNumber))}`;
      maybeNumber = `${Math.min(methodInfo?.ratio.max || 1, parseFloat(maybeNumber))}`;

      updateInputNumberValue(maybeNumber);

      return;
    }

    const groupProperty = recommendationRatio;

    if (groupProperty) {
      updateInputNumberValue(`${recommendationRatio}`);
    }
  };

  const updateInputNumberValue = (value: string) => {
    const parsedValue = parseFloat(value);

    setCurrentValue(parsedValue.toFixed(2));
    setValue(RECOMMENDATION_RATIO, parsedValue);
  };

  useEffect(() => {
    if (recommendationMethod) {
      const methodInfoObj = METHOD_INFOS[recommendationMethod];

      setMethodInfo(methodInfoObj);
      setRecommendMethods([{ value: methodInfoObj.key, label: methodInfoObj.label }]);
    }
  }, [recommendationMethod]);

  useEffect(() => {
    if (!isNaN(recommendationRatio)) {
      setCurrentValue(recommendationRatio.toFixed(2));
    }
  }, [recommendationRatio]);

  return (
    <div className="bg-white shadow-lg rounded-lg w-[600px] ml-0 p-6">
      <h1 className="text-secondary-800 text-xl font-title font-bold mb-2">Set recommendation</h1>
      <form className="w-full" onSubmit={onSubmitHandler}>
        <section className="mb-2">
          <div className="flex flex-row items-center">
            <span className="block font-semibold text-sm text-darkGray mb-1 mr-2">Method</span>
            {recommendationMethod && <MethodTooltips method={recommendationMethod} />}
          </div>
          <Select
            className="font-semibold mb-2"
            label="Base model *"
            options={recommendMethods}
            value={recommendationMethod}
            onChange={onSelectChange}
          />
        </section>
        <section className="mb-2">
          <div className="flex flex-row items-center">
            <span className="block font-semibold text-sm text-darkGray mb-1 mr-2">{methodInfo?.ratio.label || ''}</span>
            {recommendationMethod && <ConfigToolTips method={recommendationMethod} />}
          </div>
          <div className="flex flex-row flex-wrap">
            <input
              className="block w-[452px]"
              type="range"
              min={methodInfo?.ratio.min || 0}
              step={methodInfo?.ratio.step || 0.01}
              max={methodInfo?.ratio.max || 1}
              value={recommendationRatio}
              onChange={handleChangeRatio}
            />
            <input
              className="border border-defaultGray focus:border-secondary rounded w-1/6 px-3 py-1 ml-2 placeholder-defaultGray-300 focus:outline-none disabled:bg-lineGray disabled:text-defaultGray"
              type="text"
              id={RECOMMENDATION_RATIO}
              name={RECOMMENDATION_RATIO}
              value={currentValue}
              onChange={onChangeRatio}
              onBlur={onBlurRatio}
            />
          </div>
        </section>
        <div className="flex justify-end pt-2">
          <OutlineButton className="mr-3" onClick={onClose}>
            Cancel
          </OutlineButton>
          <SubmitButton type="button" onClick={onSubmit}>
            Do Recommendation
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};
