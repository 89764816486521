import React from 'react';

import NetspressoLogo from '@netspresso/components/assets/logos/netspresso-w.svg';

export const Header: React.FC = () => {
  return (
    <header className="h-18 bg-compressHeader py-3 px-20 flex items-center justify-start cursor-pointer">
      <NetspressoLogo
        onClick={() => {
          window.location.href = 'https://netspresso.ai';
        }}
      />
    </header>
  );
};
