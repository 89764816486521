import { NOT_NUMBER, NUMBER_WITH_DIGIT } from '@netspresso/shared';
import React from 'react';

export type CellPayload = { value: string; columnName: string; layerName: string };

type NetworkInputTableCellProps = {
  min: number;
  max: number;
  layerName: string;
  layerUse: boolean;
  columnName: string;
  cellValue: string | number;
  disabled?: boolean;
  includeMax?: boolean;
  onChangeCell: (payload: CellPayload) => void;
};

export const NetworkInputTableCell: React.FC<NetworkInputTableCellProps> = ({
  min,
  max,
  columnName,
  layerName,
  layerUse,
  disabled,
  cellValue,
  includeMax = true,
  onChangeCell,
}) => {
  const [currentValue, setCurrentValue] = React.useState('');

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setCurrentValue(value);
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = () => {
    let maybeNumber = currentValue.trim();

    maybeNumber = maybeNumber.replace(NOT_NUMBER, '').replace(',', '.').replace('--', '-');

    if (maybeNumber.length > 2) {
      maybeNumber = maybeNumber.charAt(0) + maybeNumber.substring(1).replace('-', '');
    }

    if (NUMBER_WITH_DIGIT.test(maybeNumber)) {
      maybeNumber = `${Math.min(max, parseFloat(maybeNumber))}`;

      updateCellValue(maybeNumber);

      return;
    }

    updateCellValue(`${cellValue}`);
  };

  const updateCellValue = (value: string) => {
    onChangeCell({ value, columnName, layerName });
    setCurrentValue(value);
  };

  const isNotValid = (value: number) => {
    return !isNaN(value) && ((includeMax ? value > max : value >= max) || value < min);
  };

  React.useEffect(() => {
    if (cellValue !== undefined && cellValue !== null) {
      setCurrentValue(`${cellValue}`);
    }
  }, [cellValue]);

  return (
    <td className="w-[166px] text-center">
      <div className="p-1 flex items-center justify-center text-xs">
        {`${min} `}&lt;
        <input
          id={`${layerName}-${columnName}`}
          type="text"
          disabled={!layerUse || disabled}
          value={currentValue}
          className={`${
            isNotValid(parseFloat(currentValue))
              ? 'border-2 border-danger focus:border-danger'
              : 'border border-defaultGray focus:border-secondary'
          } text-center w-10 m-1 text-sm`}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
        {`${includeMax ? '≤' : '<'} ${max}`}
      </div>
    </td>
  );
};
