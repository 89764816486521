import React, { useMemo } from 'react';
import { StepPointer } from '../../../../components';
import { CurrentSteps, CURRENT_STEPS } from '../../../../lib';

type CompressionStepsProps = {
  currentStep: CurrentSteps;
  onClickFirstStep?: VoidFunction;
};

export const CompressionSteps: React.FC<CompressionStepsProps> = ({ currentStep, onClickFirstStep }) => {
  const firstStepColor = useMemo(
    () => (currentStep !== CURRENT_STEPS.FIRST ? 'secondary' : 'disabledGray'),
    [currentStep]
  );

  const handleClickFirstStep: React.MouseEventHandler<HTMLButtonElement> = (_) => {
    if (onClickFirstStep) {
      onClickFirstStep();
    }
  };

  return (
    <section className="flex flex-row">
      <button
        type="button"
        className={`flex flex-col items-center -mr-[18px] ${
          currentStep !== CURRENT_STEPS.FIRST ? 'cursor-pointer' : 'pointer-events-none'
        }`}
        onClick={handleClickFirstStep}
      >
        <StepPointer
          currentCondition={currentStep === CURRENT_STEPS.FIRST}
          checkCondition={currentStep !== CURRENT_STEPS.FIRST}
        />
        <p className="w-full text-xs text-center text-secondary">Select Method</p>
      </button>

      <div className={`mt-[9px] -ml-3 w-20 h-0.5 -mr-[26px] bg-${firstStepColor}`} />

      <div className="-ml-3 flex flex-col items-center">
        <StepPointer
          defaultCondition={currentStep === CURRENT_STEPS.FIRST}
          currentCondition={currentStep === CURRENT_STEPS.SECOND || currentStep === CURRENT_STEPS.COMPLETED}
        />
        <p className={`w-24 text-xs text-center text-${firstStepColor}`}>Set Compression</p>
      </div>
    </section>
  );
};
