import React from 'react';

interface IDialog {
  title: string;
  width?: string;
  onClickCancel: React.MouseEventHandler<HTMLButtonElement>;
  onClickConfirm: CallableFunction;
  infoText: string;
  confirmDisabled?: boolean;
  cancelTitle?: string;
  confirmTitle?: string;
}

export const Dialog: React.FC<IDialog> = ({
  title,
  width,
  onClickCancel,
  onClickConfirm,
  infoText,
  confirmDisabled = false,
  cancelTitle = 'Cancel',
  confirmTitle = 'Confirm',
}) => {
  const handleClickConfirm: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClickConfirm(event);
  };

  return (
    <div className={`flex flex-col justify-between bg-white rounded shadow-lg w-[600px] p-6 ${width}`}>
      {title && <h3 className="text-xl text-gray-900 font-title font-semibold mb-4">{title}</h3>}
      {infoText && <p className="text-sm text-gray-900 pb-4 whitespace-pre-line">{infoText}</p>}
      <div className="flex justify-end">
        <button
          type="button"
          className="text-sm text-gray-700 hover:text-sub px-4 py-2 rounded font-medium leading-[14px] mr-2"
          onClick={onClickCancel}
        >
          {cancelTitle}
        </button>
        <button
          type="button"
          className="text-sm text-white bg-secondary hover:bg-secondary-active px-4 py-2 rounded font-medium leading-[14px] disabled:bg-disabledGray"
          disabled={confirmDisabled}
          onClick={handleClickConfirm}
        >
          {confirmTitle}
        </button>
      </div>
    </div>
  );
};
