import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ReactFlowProvider } from 'react-flow-renderer';
import ErrorBoundary from './ErrorBoundary';

import App from './App';
import { NotificationProvider } from './components/Notifications';
import {
  AuthContextProvider,
  CompressFormContextProvider,
  LoaderContextProvider,
  ModalContextProvider,
} from './context';
import { Compose } from './utils';

const providers = [
  LoaderContextProvider,
  ModalContextProvider,
  NotificationProvider,
  AuthContextProvider,
  CompressFormContextProvider,
  ReactFlowProvider,
];

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <Compose providers={providers}>
          <App />
        </Compose>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
