import { HeaderText, OutlineButton, SubmitButton } from '@netspresso/components';
import React from 'react';
import { CompressModel } from '../../../../../../lib';
import { CompressService } from '../../../../../../services';
import { download, downloadCSV } from '../../../../../../utils';
import { ResultBarChart } from '../ResultBarChart';

type ResultDetailsProps = {
  baseModel: CompressModel | null;
  compressedModel: CompressModel | null;
};

export const ResultDetails: React.FC<ResultDetailsProps> = ({ baseModel, compressedModel }) => {
  const onClickModelDownload: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    if (!compressedModel?.model_id) {
      return;
    }

    const res = await CompressService.downloadModel({ model_id: compressedModel?.model_id });

    if (res.status === 200) {
      const downloadUrl = res.data.url;

      if (downloadUrl) {
        download(downloadUrl);
      }
    }
  };

  const onClickDownloadCSV: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (!baseModel || !compressedModel) {
      return;
    }

    downloadCSV(baseModel, compressedModel);
  };

  if (!baseModel || !compressedModel) {
    return null;
  }

  return (
    <>
      <HeaderText className="mb-2" type="formGroup">
        Compression result details
      </HeaderText>
      <section className="grid grid-cols-2 overflow-hidden mb-2">
        <ResultBarChart
          name="FLOPs(M)"
          originalModel={baseModel.spec.flops}
          compressedModel={compressedModel.spec.flops}
          compressedLabel={`${compressedModel.spec.flops} (${(
            baseModel.spec.flops / compressedModel.spec.flops
          ).toFixed(2)}x)`}
        />
        <ResultBarChart
          name="Model Size(MB)"
          originalModel={baseModel.spec.model_size}
          compressedModel={compressedModel.spec.model_size}
          compressedLabel={`${compressedModel.spec.model_size} (${(
            baseModel.spec.model_size / compressedModel.spec.model_size
          ).toFixed(2)}x)`}
        />
        <ResultBarChart
          name="Trainable Parameters(M)"
          originalModel={baseModel.spec.trainable_parameters}
          compressedModel={compressedModel.spec.trainable_parameters}
          compressedLabel={`${compressedModel.spec.trainable_parameters} (${(
            baseModel.spec.trainable_parameters / compressedModel.spec.trainable_parameters
          ).toFixed(2)}x)`}
          hasLegend
        />
        <ResultBarChart
          name="Non Trainable Parameters(M)"
          originalModel={baseModel.spec.non_trainable_parameters}
          compressedModel={compressedModel.spec.non_trainable_parameters}
          compressedLabel={`${compressedModel.spec.non_trainable_parameters} (${
            compressedModel.spec.non_trainable_parameters !== 0
              ? (baseModel.spec.non_trainable_parameters / compressedModel.spec.non_trainable_parameters).toFixed(2)
              : '1.00'
          }x)`}
          hasLegend
        />
      </section>
      <section className="flex justify-end mb-2">
        <OutlineButton className="mr-3" onClick={onClickModelDownload}>
          Download Model
        </OutlineButton>
        <SubmitButton type="button" onClick={onClickDownloadCSV}>
          Download CSV
        </SubmitButton>
      </section>
    </>
  );
};
