export const ADVANCED_STEP = 'advanced_step';
export const COMPRESSION_TYPE = 'compression_type';
export const COMPRESSION_METHOD = 'compression_method';
export const COMPRESSION_ID = 'compression_id';
export const DESCRIPTION = 'description';
export const POLICY = 'policy';
export const RECOMMENDATION_METHOD = 'recommendation_method';
export const RECOMMENDATION_RATIO = 'recommendation_ratio';
export const DATASET_FILE = 'dataset_file';
export const BASE_MODEL = 'base_model';
export const COMPRESSED_MODEL = 'compressed_model';
export const COMPRESSION = 'compression';
export const LAYERS = 'layers';
export const OPTIONS = 'options';
export const PR_L2 = 'PR_L2';
export const PR_GM = 'PR_GM';
export const PR_NN = 'PR_NN';
export const PR_ID = 'PR_ID';
export const FD_TK = 'FD_TK';
export const FD_SVD = 'FD_SVD';
export const FD_CP = 'FD_CP';
export const CALIBRATION_RATIO = 'calibration_ratio';
export const VBMF = 'vbmf';
export const LAMP = 'lamp';
