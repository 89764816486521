import { FormHeaderLabel, HeaderText, MethodRadio } from '@netspresso/components';
import React from 'react';
import {
  BASE_MODEL,
  COMPRESSION_METHOD,
  FD_CP,
  FD_SVD,
  FD_TK,
  LAMP,
  PR_GM,
  PR_ID,
  PR_L2,
  PR_NN,
  RECOMMENDATION_METHOD,
  RECOMMENDATION_RATIO,
  VBMF,
} from '../../../../../../../../constants';
import { useCompressFormContext } from '../../../../../../../../context';
import { isCompressMethod, MethodInfoKey } from '../../../../../../../../lib';
import { parseCompressionMethod } from '../../../../../../../../utils';
import { MethodsDisplayer } from '../../../../../../components';
import { MethodInfos } from '../MethodInfos';

const methodsToShowInfo = [PR_NN, FD_TK, FD_SVD, FD_CP];

export const MethodSelector: React.FC = () => {
  const {
    compressForm: { setValue, watch },
  } = useCompressFormContext();
  const watched = watch();

  const handleChangeCompressionMethod: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    let recommendationMethod: MethodInfoKey = VBMF;
    let recommendationRatio = 0;

    if ([FD_TK, FD_SVD].includes(value)) {
      recommendationMethod = VBMF;
      recommendationRatio = 0;
    } else if ([PR_L2, PR_GM, PR_NN].includes(value)) {
      recommendationMethod = LAMP;
      recommendationRatio = 0.5;
    }

    // PR_ID는 추천 없음
    if (isCompressMethod(value)) {
      setValue(COMPRESSION_METHOD, value, { shouldDirty: true, shouldValidate: true });
      setValue(RECOMMENDATION_METHOD, recommendationMethod);
      setValue(RECOMMENDATION_RATIO, recommendationRatio);
    }
  };

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Method
      </HeaderText>
      <section className="mb-4">
        <FormHeaderLabel>Method *</FormHeaderLabel>
        <div className="flex flex-row">
          <div className="w-1/4">
            <span className="text-xs">Structured Pruning (Criteria)</span>
            <div className="flex flex-col gap-1">
              <MethodRadio
                value={PR_L2}
                label={parseCompressionMethod(PR_L2)}
                groupName={COMPRESSION_METHOD}
                isChecked={watched[COMPRESSION_METHOD] === PR_L2}
                onChange={handleChangeCompressionMethod}
              />
              <MethodRadio
                value={PR_GM}
                label={parseCompressionMethod(PR_GM)}
                groupName={COMPRESSION_METHOD}
                isChecked={watched[COMPRESSION_METHOD] === PR_GM}
                onChange={handleChangeCompressionMethod}
              />
            </div>
            <span className="text-xs">Structured Pruning (Index)</span>
            <div className="flex flex-col gap-1">
              <MethodRadio
                value={PR_ID}
                label={parseCompressionMethod(PR_ID)}
                groupName={COMPRESSION_METHOD}
                isChecked={watched[COMPRESSION_METHOD] === PR_ID}
                onChange={handleChangeCompressionMethod}
              />
            </div>
            <span className="text-xs">Filter Decomposition</span>
            <div className="flex flex-col gap-1">
              <MethodRadio
                value={FD_TK}
                label={parseCompressionMethod(FD_TK)}
                groupName={COMPRESSION_METHOD}
                isChecked={watched[COMPRESSION_METHOD] === FD_TK}
                onChange={handleChangeCompressionMethod}
              />
              <MethodRadio
                value={FD_SVD}
                label={parseCompressionMethod(FD_SVD)}
                groupName={COMPRESSION_METHOD}
                isChecked={watched[COMPRESSION_METHOD] === FD_SVD}
                onChange={handleChangeCompressionMethod}
              />
              <MethodRadio
                value={FD_CP}
                label={parseCompressionMethod(FD_CP)}
                groupName={COMPRESSION_METHOD}
                isChecked={watched[COMPRESSION_METHOD] === FD_CP}
                onChange={handleChangeCompressionMethod}
              />
            </div>
          </div>
          <MethodsDisplayer method={watched[COMPRESSION_METHOD]} />
        </div>
        {methodsToShowInfo.includes(watched[COMPRESSION_METHOD] || '') && (
          <MethodInfos method={watched[COMPRESSION_METHOD]} baseModel={watched[BASE_MODEL]} />
        )}
      </section>
    </>
  );
};
