import { HeaderText, OutlineButton, SubmitButton } from '@netspresso/components';
import React from 'react';
import { BASE_MODEL, COMPRESSED_MODEL } from '../../../../constants';
import { useCompressFormContext } from '../../../../context';
import { ResultContent, ResultDetails } from './components';

type CompressionResultProps = {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
};

export const CompressionResult: React.FC<CompressionResultProps> = ({ onClose, onSubmit }) => {
  const {
    compressForm: { getValues },
  } = useCompressFormContext();

  const compressedModel = getValues(COMPRESSED_MODEL);
  const baseModel = getValues(BASE_MODEL);

  return (
    <div className="bg-white shadow-lg rounded-lg w-[900px] max-h-[calc(100%-74px)] ml-0 py-6">
      <HeaderText className="mb-2 px-6" type="main">
        Compression Result
      </HeaderText>
      <section className="px-6 max-h-[calc(100vh-148px)] overflow-y-scroll">
        <ResultDetails baseModel={baseModel} compressedModel={compressedModel} />
        <HeaderText className="mb-2" type="formGroup">
          {compressedModel?.status.is_retrainable
            ? 'Do you want to retrain the model?'
            : 'Compressed model needs to be retrained.'}
        </HeaderText>
        <ResultContent isRetrainable={compressedModel?.status.is_retrainable} />
        <div className="flex justify-end pt-2">
          <OutlineButton className="mr-3" onClick={onClose}>
            Close
          </OutlineButton>
        </div>
      </section>
    </div>
  );
};
